<script>
import _ from 'lodash'
import { mapActions, mapState } from 'vuex'

import { toastMixins, numberMixins } from '@/mixins'
import ge2gjnlService from '@/services/entry/ge2gjnl.js'

import DetailsForm from '../components/DetailsForm.vue'
import HeaderInformationForm from '../components/HeaderInformationForm.vue'
import {
  journalBookImportAdapter,
  journalHeaderImportAdapter,
  journalDetailImportAdapter
} from '../helpers/adapter'
import validateMixins from '../mixins/validate'

export default {
  mixins: [toastMixins, validateMixins, numberMixins],
  components: {
    HeaderInformationForm,
    DetailsForm
  },
  data() {
    return {
      journalBookId: null,
      journalBookCode: null,
      documentNumber: null,
      documentId: null,
      journalBook: null,
      document: null,
      journalTransactionDetails: [],
      journalTransactionHeader: {},
      detailsFromTemplate: [],
      errMsg: null
    }
  },
  computed: {
    ...mapState({
      defaultDelayMs: state => state.constants.defaultDelayMs
    })
  },
  async created() {
    this.journalBookId = this.$route.params.journalBookId
    await this.setJournalBook()

    this.journalBookCode = this.$route.params.journalCode
    this.documentId = this.$route.params.documentId

    await this.setDocument()
    await this.setJournalTransactionHeader()
    await this.setJournalTransactionDetails()
  },
  methods: {
    ...mapActions({
      setAppLoading: 'setAppLoading'
    }),
    async setJournalBook() {
      try {
        this.setAppLoading(true)
        const res = await ge2gjnlService.getAllGe2gjnl()
        this.journalBook = journalBookImportAdapter(
          res.data.data.find(
            journalBook =>
              Number(journalBook.glbjncd_id) === Number(this.journalBookId)
          )
        )
      } catch (err) {
        const errMessage =
          err.response?.data.thMessage || 'ดึงข้อมูลสมุดรายวันไม่สำเร็จ'
        this.errMsg = errMessage
      } finally {
        this.setAppLoading(false)
      }
    },
    async setDocument() {
      try {
        this.setAppLoading(true)
        const res = await ge2gjnlService.getGe2gjnlDataByJournalCodeAndDocumentId(
          this.journalBookCode,
          this.documentId
        )
        const { information, paginationInfo, tableDetails } = res.data.data
        this.document = {
          paginationInfo,
          information: journalHeaderImportAdapter(information),
          tableDetails: tableDetails.map(detail =>
            journalDetailImportAdapter(detail)
          )
        }

        // If try to edit the deleted document, redirect to detail page
        if (!!this.document.information.deletedAt) {
          this.errMsg = 'ไม่สามารถแก้ไขรายการที่ยกเลิกไปแล้วได้'
          setTimeout(() => {
            this.$router.replace(
              `/entry/ge2gjnl/journal-book/${this.journalBookId}/detail`
            )
          }, this.defaultDelayMs)
        }
      } catch (err) {
        const errMessage =
          err.response?.data.thMessage || 'ดึงรายการข้อมูลสมุดรายวันไม่สำเร็จ'
        this.errMsg = errMessage
      } finally {
        this.setAppLoading(false)
      }
    },
    async setJournalTransactionHeader() {
      const { information: info } = this.document
      const documentDate = info.documentDate

      if (documentDate) {
        const documentDateSplitted = documentDate.split('-')
        const day = documentDateSplitted[2]
        const month = documentDateSplitted[1]
        const year = Number(documentDateSplitted[0]) + 543
        this.journalTransactionHeader.documentDateTH = `${day}/${month}/${year}`
      }

      this.journalTransactionHeader = {
        ...this.journalTransactionHeader,
        ...info,
        journalCode: this.journalBookCode,
        documentId: this.documentId
      }
    },
    async setJournalTransactionDetails() {
      const { tableDetails: details } = this.document
      const unOrderedDetails = details.map(detail => {
        const {
          sequenceNo,
          accountNumber,
          accountName,
          remark,
          debitAmount,
          creditAmount
        } = detail
        return {
          order: sequenceNo,
          accountNumber,
          accountName,
          remark,
          debitAmount: Number(debitAmount) === 0 ? null : debitAmount,
          creditAmount: Number(creditAmount) === 0 ? null : creditAmount
        }
      })
      this.journalTransactionDetails = _.orderBy(
        unOrderedDetails,
        ['order'],
        ['asc']
      ).map((el, index) => ({ ...el, id: index + 1 }))
    },
    async saveButtonClickedHandler(payload) {
      const { journalTransactionHeader: journalHeader } = payload
      const journalDetails = this.journalTransactionDetails.map(
        ({ debitAmount, creditAmount, ...restDetail }, index) => {
          return {
            ...restDetail,
            id: index + 1,
            debitAmount:
              Number(debitAmount) === 0 ? null : Number(debitAmount).toFixed(2),
            creditAmount:
              Number(creditAmount) === 0
                ? null
                : Number(creditAmount).toFixed(2)
          }
        }
      )

      const validatedResult = this.mxValidateRequest({
        journalHeader,
        journalDetails
      })
      if (!validatedResult) return

      const {
        journalHeaderValidated,
        journalDetailsValidated
      } = validatedResult

      this.journalTransactionDetails = journalDetailsValidated

      const body = {
        ...journalHeaderValidated,
        details: this.journalTransactionDetails
      }
      delete body.documentDateTH

      try {
        this.setAppLoading(true)
        await ge2gjnlService.updateDocument(body)
        this.mxDisplaySuccessMessage('แก้ไขรายการสมุดรายวันสำเร็จ')
        setTimeout(() => {
          this.$router.replace(
            `/entry/ge2gjnl/journal-book/${this.journalBookId}/detail`
          )
        }, this.defaultDelayMs)
      } catch (err) {
        this.errMsg = err.response.data.thMessage
      } finally {
        this.setAppLoading(false)
      }
    },
    detailUpdatedHandler(payload) {
      if (!_.isEqual(this.journalTransactionDetails, payload)) {
        this.journalTransactionDetails = payload
      }
    },
    async templateSelectedHandler(template) {
      const { id } = template
      try {
        const res = await ge2gjnlService.getJournalDocumentTemplateById(id)
        const { details } = res.data.data

        const journalTransactionDetailsClone = [
          ...this.journalTransactionDetails
        ]

        details.forEach(detail => {
          const {
            remark,
            accountNumber,
            accountName,
            debitAmount,
            creditAmount
          } = detail

          journalTransactionDetailsClone.push({
            id: journalTransactionDetailsClone.length + 1,
            order: journalTransactionDetailsClone.length + 1,
            accountNumber,
            accountName,
            remark,
            debitAmount,
            creditAmount
          })
        })

        this.journalTransactionDetails = [...journalTransactionDetailsClone]
      } catch (err) {
        const errMessage =
          err.response?.data.thMessage || 'ดึงข้อมูลต้นแบบป้อนรายการไม่สำเร็จ'
        this.errMsg = errMessage
      }
    }
  }
}
</script>

<template>
  <div>
    <b-container fluid="lg">
      <!-- page title -->
      <b-row align-v="center">
        <b-col class="col-12 col-lg-6 py-3 text-center text-lg-left">
          <span class="h2 text-white" style="text-shadow: 2px 2px grey;">
            แก้ไขรายการสมุดรายวัน
          </span>
        </b-col>
      </b-row>
    </b-container>

    <b-container fluid>
      <!-- header + information input -->
      <div class="my-3">
        <header-information-form
          :mode="'UPDATE'"
          :journalBookProp="journalBook"
          :journalTransactionHeaderProp="journalTransactionHeader"
          @saveButtonClicked="saveButtonClickedHandler"
          @templateSelected="templateSelectedHandler"
        ></header-information-form>
      </div>

      <!-- details input -->
      <div>
        <details-form
          :modeProp="'UPDATE'"
          :journalTransactionDetailsProp="journalTransactionDetails"
          @detailUpdated="detailUpdatedHandler"
        ></details-form>
      </div>
    </b-container>

    <error-modal
      :displayProp="!!errMsg"
      :errorMessageProp="errMsg"
      @modalClosed="errMsg = null"
    ></error-modal>
  </div>
</template>

<style></style>
